import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../shared/models/user/user';

@Pipe({
  name: 'userFilter',
  pure: false
})
export class UserFilterPipe implements PipeTransform {
  transform(items: any[], filter: User): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter(item =>
      (item.corporateCode && filter.corporateCode && item.corporateCode.indexOf(filter.corporateCode) !== -1) ||
      (item.id && filter.id && item.id.indexOf(filter.id) !== -1)
    );
  }
}
