import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { Role } from '../shared/models/enums';

import { CorporationPageComponent } from './corporation-page/corporation-page.component';

export const routing: ModuleWithProviders = RouterModule.forChild([
  {
    path: 'corporations',
    component: CorporationPageComponent,
    canActivate: [PermissionGuard],
    data: { roles: [Role.SuperAdministrator] }
  },
]);
