export class AdminStats {
  constructor(item) {
    this.UserCount = item.userCount;
    this.ActiveUsersToday = item.activeUsersToday;
    this.ActiveUsersLast7Days = item.activeUsersLast7Days;
    this.ActiveSensorsToday = item.activeSensorsToday;
    this.ActiveSensorsLast7Days = item.activeSensorsLast7Days;
    this.CorporationCount = item.corporationCount;
  }

  UserCount: number;
  ActiveUsersToday: number;
  ActiveUsersLast7Days: number;
  ActiveSensorsToday: number;
  ActiveSensorsLast7Days: number;
  CorporationCount: number;
}
