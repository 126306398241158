import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { Http } from '@angular/http';
import { Injector } from '@angular/core';
import { ApiTemplates } from '../utils/api-templates';
import { User } from '../models/user/user';
import { Users } from '../models/user/users';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  constructor(private http: Http,
    private authenticationService: AuthenticationService,
    injector: Injector) {
    super(injector);
  }

  getAll() {
    return this.http
      .get(
        ApiTemplates.USER_GETALL.expand(),
        { headers: this.getHttpHeaders() }
      )
      .map(res => new Users(res.json()))
      .catch(this.handleError);
  }

  create(user: User) {
    return this.http
      .post(
        ApiTemplates.USER_CREATE.expand(),
        user, { headers: this.getHttpHeaders() }
      )
      .catch(this.handleError);
  }

  edit(user: User) {
    return this.http
      .put(
        ApiTemplates.USER_EDIT.expand({ userId: user.id }),
        user, { headers: this.getHttpHeaders() }
    )
    .catch(this.handleError);
  }

  resetPassword(oldPassword: string, newPassword: string) {
    return this.http
      .put(ApiTemplates.USER_RESETPASSWORD.expand({
        userId: this.authenticationService.loggedInUser.id,
      }),
      { oldPassword: oldPassword, password:newPassword}, { headers: this.getHttpHeaders() })
      .catch(this.handleError);
  }
}
