import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Http } from '@angular/http';
import { ApiTemplates } from '../utils/api-templates';
import { AdminStats } from '../models/dashboards/admin-stats';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminStatsService extends BaseService {
  constructor(private http: Http,
    injector: Injector) {
    super(injector);
  }

  get() {
    return this.http
      .get(
        ApiTemplates.ADMINSTATS.expand(),
        { headers: this.getHttpHeaders() }
      )
      .map(res => new AdminStats(res.json()))
      .catch(this.handleError);
  }
}
