import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Http } from '@angular/http';
import { ApiTemplates } from '../utils/api-templates';
import { PostureDataList } from '../models/dashboards/posture-data-list';
import { PostureUserDataList } from '../models/dashboards/posture-user-data-list';
import { PostureStatusDataList } from '../models/dashboards/posture-status-data-list';
import { PostureFilter } from '../models/dashboards/posture-filter';
import { Injector } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class PostureDataService extends BaseService {
  constructor(private http: Http,
    injector: Injector) {
    super(injector);
  }

  getPostureByDay(filter: PostureFilter) {
    return this.http
      .get(
        ApiTemplates.POSTUREDATA_GETPOSTUREBYDAY.expand(filter),
        { headers: this.getHttpHeaders() }
      )
      .map(res => new PostureDataList(res.json()))
      .catch(this.handleError);
  }

  getActiveUsersByDay(filter: PostureFilter) {
    return this.http
      .get(
        ApiTemplates.POSTUREDATA_GETACTIVEUSERSBYDAY.expand(filter),
        { headers: this.getHttpHeaders() }
      )
      .map(res => new PostureUserDataList(res.json()))
      .catch(this.handleError);
  }

  getStatusChangesByHour(filter: PostureFilter) {
    return this.http
      .get(
        ApiTemplates.POSTUREDATA_GETSTATUSCHANGESBYHOUR.expand(filter),
        { headers: this.getHttpHeaders() }
      )
      .map(res => new PostureStatusDataList(res.json()))
      .catch(this.handleError);
  }
}
