import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import 'rxjs/add/operator/finally';

import { UserService } from '../../shared/services/user.service';
import { AuthenticationService } from '../../shared/services/authentication.service';


@Component({
  selector: 'app-user-resetpassword-page',
  templateUrl: './user-resetpassword-page.component.html',
  styleUrls: ['./user-resetpassword-page.component.css']
})
export class UserResetPasswordPageComponent implements OnInit, OnDestroy {
  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
  }

  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  isBusy: boolean;

  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    
  }

  reset() {
    this.isBusy = true;
    this.userService.resetPassword(this.oldPassword, this.newPassword)
      .finally(() => this.isBusy = false)
      .subscribe(() => {});
  }
}
