export class Token {
  constructor(item) {
    this.accessToken = item.accessToken;
    this.tokenType = item.tokenType;
    this.expiresInSeconds = item.expiresInSeconds;
    this.idToken = item.idToken;
    this.refreshToken = item.refreshToken;
  }

  accessToken: string;
  tokenType: string;
  expiresInSeconds: number;
  idToken: string;
  refreshToken: string;
}
