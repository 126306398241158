export class PostureData {
  constructor(item) {
    this.Date = item.date;
    this.GoodPostureTimeSeconds = item.goodPostureTimeSeconds;
    this.BadPostureTimeSeconds = item.badPostureTimeSeconds;
    this.UnknownPostureTimeSeconds = item.unknownPostureTimeSeconds;
  }

  Date: Date;
  GoodPostureTimeSeconds: number;
  BadPostureTimeSeconds: number;
  UnknownPostureTimeSeconds: number;
}
