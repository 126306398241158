import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { Subject } from 'rxjs';

import { PostureDataService } from '../../../shared/services/posture-data.service';
import { CorporationService } from '../../../shared/services/corporation.service';

import { PostureUserData } from '../../../shared/models/dashboards/posture-user-data';
import { PostureStatusData } from '../../../shared/models/dashboards/posture-status-data';
import { PostureData } from '../../../shared/models/dashboards/posture-data';
import { PostureFilter } from '../../../shared/models/dashboards/posture-filter';
import { Posture, AgeRange, Gender, ExportDataOptions, JobType } from '../../../shared/models/enums';
import { AuthenticationService } from '../../../shared/services/authentication.service';

import { DateHelper } from '../../../shared/utils/date-helper';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { User } from '../../../shared/models/user/user';
import { Role } from '../../../shared/models/enums';

@Component({
  selector: 'app-corporation-dashboard',
  templateUrl: './corporation-dashboard.component.html',
  styleUrls: ['./corporation-dashboard.component.css']
})
export class CorporationDashboardComponent implements OnInit {
  filter: PostureFilter;
  $delayedFilter: Subject<void> = new Subject<void>();
  corporationCodes: string[];
  ageRange = AgeRange;
  gender = Gender;
  jobType = JobType;

  chartMetadata = [
    {
        label: Posture[Posture.Good],
        backgroundColor: '#00b1a0'
    },
    {
      label: Posture[Posture.Bad],
      backgroundColor: '#de3d3d'
    },
    {
      label: Posture[Posture.Unknown],
      backgroundColor: '#0d2527'
    }
  ];

  postureDataList: PostureData[];
  postureUserDataList: PostureUserData[];
  postureStatusDataList: PostureStatusData[];

  postureBreakdownChart: any = [];
  postureByDayChart: any = [];
  postureActiveUsersChart: any = [];
  postureStatusChangesChart: any = [];

  exportDataOptions = ExportDataOptions;
  dataToExport: ExportDataOptions = ExportDataOptions.Posture;

  currentUser: User = this.authenticationService.currentUserValue;
  userIsCorpAdmin: boolean = this.currentUser.role === Role.CorporateAdministrator;

  constructor(
    private postureDataService: PostureDataService,
    private corporationService: CorporationService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.$delayedFilter.debounceTime(1000).subscribe(() => this.rerenderCharts());

    this.filter = {
      corporationCode: null,
      gender: null,
      ageRange: null,
      jobType: null,
      dateMin: DateHelper.getDateOneMonthAgoAsISODateString(),
      dateMax: DateHelper.getCurrentDateAsISODateString()
    };

    this.getCorporationCodes();
    this.renderCharts();
  }

  private renderCharts() {
    if (this.currentUser && this.userIsCorpAdmin) {
      this.filter.corporationCode = this.currentUser.corporateCode;
    }

    this.postureDataService.getPostureByDay(this.filter).subscribe(result => {
      this.postureDataList = result.postureDataList;
      this.renderPostureBreakdownChart(this.postureDataList);
      this.renderPostureByDayChart(this.postureDataList);
    },
      error => {
        console.log(error);
      }
    );

    this.postureDataService.getActiveUsersByDay(this.filter).subscribe(
      result => {
        this.postureUserDataList = result.postureUserDataList;
        this.renderPostureActiveUsersByDayChart(this.postureUserDataList);
      },
      error => console.log(error));

    this.postureDataService.getStatusChangesByHour(this.filter).subscribe(
      result => {
        this.postureStatusDataList = result.postureStatusDataList;
        this.renderPostureStatusChangesChart(this.postureStatusDataList)
      },
      error => console.log(error));
  }

  rerenderCharts() {
    this.postureBreakdownChart.destroy();
    this.postureByDayChart.destroy();
    this.postureActiveUsersChart.destroy();
    this.postureStatusChangesChart.destroy();
    this.renderCharts();
  }

  applyFilters() {
    this.rerenderCharts();
  }

  applyDelayedFilters() {
    this.$delayedFilter.next();
  }

  exportData() {
    let data: any;

    switch (ExportDataOptions[this.dataToExport]) {
      case ExportDataOptions[ExportDataOptions.Posture]:
        data = this.postureDataList;
        break;
      case ExportDataOptions[ExportDataOptions.ActiveUsers]:
        data = this.postureUserDataList;
        break;
      case ExportDataOptions[ExportDataOptions.StatusChanges]:
        data = this.postureStatusDataList;
        break;
    }

    const fileName = `${ExportDataOptions[this.dataToExport]}_${new Date().getTime()}`;

    const options = {
      showLabels: true,
      headers: Object.keys(data[0])
    };

    new ngxCsv(data, fileName, options);
  }

  private renderPostureBreakdownChart(postureDataList: any) {
    const data = [
      postureDataList.map(c => c.GoodPostureTimeSeconds).reduce((c, g) => c + g, 0),
      postureDataList.map(c => c.BadPostureTimeSeconds).reduce((c, g) => c + g, 0),
      postureDataList.map(c => c.UnknownPostureTimeSeconds).reduce((c, g) => c + g, 0)
    ];

    this.postureBreakdownChart = new Chart(document.getElementById('postureBreakdownChart'), {
      type: 'pie',
      data: {
        labels: this.chartMetadata.map(m => m.label),
        datasets: [{
          data: data,
          backgroundColor: this.chartMetadata.map(m => m.backgroundColor)
        }]
      },
      options: {
        title: {
          display: true,
          text: 'Posture Breakdown'
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, chartData) {
              const dataset = chartData.datasets[tooltipItem.datasetIndex];
              const meta = dataset._meta[Object.keys(dataset._meta)[0]];
              const total = meta.total;
              const currentValue = dataset.data[tooltipItem.index];
              const percentage = parseFloat((currentValue / total * 100).toFixed(1));

              function secondsToHms(d) {
                d = Number(d);
                const h = Math.floor(d / 3600);
                const m = Math.floor(d % 3600 / 60);
                const s = Math.floor(d % 3600 % 60);

                const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
                const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
                const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
                return hDisplay + mDisplay + sDisplay;
              }

              return secondsToHms(currentValue) + ' (' + percentage + '%)';
            },
            title(tooltipItem, chartData) {
              return chartData.labels[tooltipItem[0].index];
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }

  private renderPostureByDayChart(postureDataList: any) {
    const labels = postureDataList.map(p => DateHelper.dateToString(p.Date))
      .filter((item, i, ar) => ar.indexOf(item) === i);

    const summedPostureDataList: PostureData[] = [];
    labels.forEach(label => {
      const groupedPostureDataList = postureDataList.filter(p => DateHelper.dateToString(p.Date) === label);
      const summedPostureData: PostureData = {
        Date: label,
        GoodPostureTimeSeconds: groupedPostureDataList.map(c => c.GoodPostureTimeSeconds).reduce((c, g) => c + g, 0),
        BadPostureTimeSeconds: groupedPostureDataList.map(c => c.BadPostureTimeSeconds).reduce((c, g) => c + g, 0),
        UnknownPostureTimeSeconds: groupedPostureDataList.map(c => c.UnknownPostureTimeSeconds).reduce((c, g) => c + g, 0),
      };

      summedPostureDataList.push(summedPostureData);
    });

    let datasets = [];
    datasets = [
      {
        data: summedPostureDataList.map(p => p.GoodPostureTimeSeconds)
      },
      {
        data: summedPostureDataList.map(p => p.BadPostureTimeSeconds)
      },
      {
        data: summedPostureDataList.map(p => p.UnknownPostureTimeSeconds)
      },
    ];

    for (let i = 0; i < datasets.length; i++) {
      datasets[i]['label'] = this.chartMetadata[i].label;
      datasets[i]['backgroundColor'] = this.chartMetadata[i].backgroundColor;
      datasets[i]['fill'] = false;
      datasets[i]['lineTension'] = 0;
    }

    this.postureByDayChart = new Chart(document.getElementById('postureByDayChart'), {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        title: {
          display: true,
          text: 'Good vs Bad Posture By Day'
        },
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
          }]
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, chartData) {
              const dataset = chartData.datasets[tooltipItem.datasetIndex];
              const currentValue = dataset.data[tooltipItem.index];

              function secondsToHms(d) {
                d = Number(d);
                const h = Math.floor(d / 3600);
                const m = Math.floor(d % 3600 / 60);
                const s = Math.floor(d % 3600 % 60);

                const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
                const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
                const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
                return hDisplay + mDisplay + sDisplay;
              }

              return secondsToHms(currentValue);
            },
            title(tooltipItem, chartData) {
              return chartData.labels[tooltipItem[0].index];
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }

  private renderPostureActiveUsersByDayChart(postureUserDataList: PostureUserData[]) {
    const datasets = [];

    if (this.filter.corporationCode) {
      const deviceLineChart = {
        type: 'line',
        label: 'Devices',
        backgroundColor: '#065272',
        fill: false,
        data: postureUserDataList.map(p => p.DeviceCount)
      };

      datasets.push(deviceLineChart);
    }

    const userBarChart = {
      type: 'bar',
      label: 'Users',
      backgroundColor: this.chartMetadata[0].backgroundColor,
      data: postureUserDataList.map(p => p.NumUsers)
    };

    datasets.push(userBarChart);

    this.postureActiveUsersChart = new Chart(document.getElementById('postureActiveUsersChart'), {
      type: 'bar',
      data: {
        labels: postureUserDataList.map(p => DateHelper.dateToString(p.Date)),
        datasets: datasets
      },
      options: {
        title: {
          display: true,
          text: '# Active Users By Day'
        },
        scales: {
          yAxes: [{
            ticks: {
              precision: 0
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }

  private renderPostureStatusChangesChart(postureStatusDataList: PostureStatusData[]) {
    const hours = [];

    for (let i = 0; i < 24; i++) {
      const hour = `${i}:00`;
      hours.push(hour);
    }

    const datasets = [];

    for (let i = 0; i < this.chartMetadata.length - 1; i++) {
      const data = [];

      hours.forEach(hour => {
        let seconds = 0;

        postureStatusDataList.forEach(item => {
          if (`${item.Hour}:00` === hour &&
           this.chartMetadata[i].label === Posture[item.Posture]) {
            seconds = item.Seconds;
          }
        });

        data.push(seconds);
      });

      const datasetItem = {
        label: this.chartMetadata[i].label,
        backgroundColor: this.chartMetadata[i].backgroundColor,
        data: data
      };

      datasets.push(datasetItem);
    }

    this.postureStatusChangesChart = new Chart(document.getElementById('postureStatusChangesChart'), {
      type: 'bar',
      data: {
        labels: hours,
        datasets: datasets
      },
      options: {
        title: {
          display: true,
          text: '# Status Changes By Hour'
        },
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              precision: 0
            }
          }]
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, chartData) {
              const dataset = chartData.datasets[tooltipItem.datasetIndex];
              const currentValue = dataset.data[tooltipItem.index];

              function secondsToHms(d) {
                d = Number(d);
                const h = Math.floor(d / 3600);
                const m = Math.floor(d % 3600 / 60);
                const s = Math.floor(d % 3600 % 60);

                const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
                const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
                const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
                return hDisplay + mDisplay + sDisplay;
              }

              return secondsToHms(currentValue);
            },
            title(tooltipItem, chartData) {
              return chartData.labels[tooltipItem[0].index];
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }

  getMaxDateForMinDate() {
    if (this.filter.dateMax) {
      const date = new Date(this.filter.dateMax);
      date.setDate(date.getDate());
      return DateHelper.dateToISODateString(date);
    } else {
      return null;
    }
  }

  getMinDateForMaxDate() {
    if (this.filter.dateMin) {
      const date = new Date(this.filter.dateMin);
      date.setDate(date.getDate());
      return DateHelper.dateToISODateString(date);
    } else {
      return null;
    }
  }

  private getCorporationCodes() {
    this.corporationService.getAll().subscribe(
      result => this.corporationCodes = result.corporations.map(u => u.code),
      error => console.log(error));
  }
}
