import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyFocusDirective } from '../directives/focus.directive';
import { SpinnerComponent } from '../../spinner/spinner.component';
import { KeysPipe } from '../pipes/keys-pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MyFocusDirective, SpinnerComponent, KeysPipe],
  exports: [MyFocusDirective, SpinnerComponent, KeysPipe],
  providers: []
})
export class SharedModule { }
