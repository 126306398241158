import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { SharedModule } from '../../shared/modules/shared.module';
import { routing } from './corporation-dashboard.routing';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule, AlertModule } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { CorporationDashboardComponent } from './corporation-dashboard/corporation-dashboard.component';

import { PostureDataService } from '../../shared/services/posture-data.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    SharedModule,
    routing,
    DataTablesModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    NgSelectModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  declarations: [CorporationDashboardComponent],
  providers: [PostureDataService]
})
export class CorporationDashboardModule { }
