import { Role, Gender, AgeRange, JobType } from '../enums'

export class User {
  constructor(item) {
    this.id = item.id;
    this.identifier = item.identifier;
    this.email = item.email;
    this.password = item.password;
    this.confirmPassword = item.confirmPassword;
    this.oldPassword = item.oldPassword;
    this.firstName = item.firstName || "";
    this.lastName = item.lastName || "";
    this.corporateCode = item.corporateCode;
    this.role = item.role;
    this.gender = item.gender;
    this.height = item.height;
    this.weight = item.weight;
    this.ageRange = item.ageRange;
    this.jobType = item.jobType;
  }

  id?: string;
  identifier: string;
  email: string;
  password: string;
  confirmPassword: string;
  oldPassword: string;
  firstName: string;
  lastName: string;
  corporateCode: string;
  role: Role;
  gender: Gender;
  height: number;
  weight: number;
  ageRange: AgeRange;
  jobType: JobType;
}
