import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { routing } from './app.routing';

import { CorporationModule } from './corporation/corporation.module';
import { UserModule } from './user/user.module';
import { LoginModule } from './login/login.module';
import { CorporationDashboardModule } from './dashboards/corporations/corporation-dashboard.module';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CorporationPageComponent } from './corporation/corporation-page/corporation-page.component';
import { LoginPageComponent } from './login/login-page/login-page.component';
import { UserPageComponent } from './user/user-page/user-page.component';
import { CorporationDashboardComponent } from './dashboards/corporations/corporation-dashboard/corporation-dashboard.component';

import { ConfigurationService } from './shared/services/configuration.service';
import { AuthenticationService } from './shared/services/authentication.service';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpModule,
    HttpClientModule,
    FormsModule,
    CorporationModule,
    LoginModule,
    UserModule,
    routing,
    CorporationDashboardModule
  ],
  providers: [
    // Register our config service as a provided service that
    // components may request.
    ConfigurationService,
    AuthenticationService,
    {
      // Here we request that configuration loading be done at app-
      // initialization time (prior to rendering)
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService, authenticationService: AuthenticationService) =>
        () => configService.loadConfigurationData()
          .then(() => authenticationService.init()),
      deps: [ConfigurationService, AuthenticationService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
