import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Http } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { ApiTemplates } from '../utils/api-templates';
import { ConfigurationService } from './configuration.service';
import { Injector } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Token } from '../models/authentication/token';
import { User } from '../../shared/models/user/user';
import { Headers } from '@angular/http';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class AuthenticationService {

  constructor(
    private configurationService: ConfigurationService,
    private http: Http,
    private httpClient: HttpClient,
    private injector: Injector) {
  }

  token: Token;
  loggedInUser: User;
  refreshTokenInterval: any;

  isLogged(): boolean {
    return this.loggedInUser != null;
  }

  init() {
    // prevent accessing the login page if already logged
    const router = this.injector.get(Router);
    router.events.subscribe((res) => {
      if (res instanceof NavigationStart && res.url === '/login' && this.isLogged()) {
        router.navigate(["/"]);
      }
    });

    return this.loadToken()
      .then(() => {
        this.setRefreshTokenInterval();
      })
      .catch(() => this.logout());
  }
  

  login(username: string, password: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.httpClient
        .post<Token>(ApiTemplates.LOGIN.expand({ username: username, password: password }), null)
        .toPromise()
        .then((result: Token) => {
          this.setToken(result, username);
          this.setRefreshTokenInterval();
          this.loadUser(username).then(() => resolve(true)).catch(() => reject('Cannot load the user'));
        })
        .catch(reject);
    });
  }

  logout() {
    this.token = null;
    this.loggedInUser = null;
    clearInterval(this.refreshTokenInterval);
    localStorage.removeItem("hb_token_accessToken");
    localStorage.removeItem("hb_token_expiresInSeconds");
    localStorage.removeItem("hb_token_idToken");
    localStorage.removeItem("hb_token_refreshToken");
    localStorage.removeItem("hb_token_tokenType");
    localStorage.removeItem("hb_loggedin_username");

    this.redirectToLogin();
  }

  public get currentUserValue(): User {
    return this.loggedInUser;
  }

  refreshToken(me) {
    return me.http.post(ApiTemplates.REFRESH_TOKEN.expand(), me.token, me.getHttpHeaders())
      .subscribe(result => {
        me.setToken(new Token(result.json()));
      });
  }

  setRefreshTokenInterval() {
    var me = this;
    this.refreshTokenInterval = setInterval(() => me.refreshToken(me), 1000 * 60 * 30);
  }

  private setToken(token: Token, username?: string) {
    this.token = token;
    localStorage.setItem("hb_token_accessToken", this.token.accessToken);
    localStorage.setItem("hb_token_expiresInSeconds", this.token.expiresInSeconds.toString());
    localStorage.setItem("hb_token_idToken", this.token.idToken);
    localStorage.setItem("hb_token_refreshToken", this.token.refreshToken);
    localStorage.setItem("hb_token_tokenType", this.token.tokenType);
    if (username) {
      localStorage.setItem("hb_loggedin_username", username);
    }
  }

  private getHttpHeaders() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (this.token != null) {
      headers.append('Authorization', 'Bearer ' + this.token.accessToken);
    }
    return { headers: headers };
  }

  private redirectToLogin() {
    this.injector.get(Router).navigate(["/login"]);
  }

  private loadToken() {

    if (localStorage.getItem('hb_token_accessToken') !== null) {
      // get token
      this.token = new Token({
        accessToken: localStorage.getItem('hb_token_accessToken'),
        tokenType: localStorage.getItem('hb_token_tokenType'),
        expiresInSeconds: Number(localStorage.getItem('hb_token_expiresInSeconds')),
        idToken: localStorage.getItem('hb_token_idToken'),
        refreshToken: localStorage.getItem('hb_token_refreshToken')
      });

      // get user
      return this.loadUser(localStorage.getItem('hb_loggedin_username'));

    } else {
      return Promise.reject('cannot get token from local storage');
    }
  }

  private loadUser(username: string): Promise<User> {
    return this.http.get(ApiTemplates.USER_GET.expand({ username: username }),this.getHttpHeaders())
      .map(res => this.loggedInUser = new User(res.json()))
      .toPromise();
  }
}
