import { PostureStatusData } from './posture-status-data';
import { PostureStatusDataExtensions } from './posture-status-data-extensions';

export class PostureStatusDataList {
  constructor(response) {
    if (response) {
      this.postureStatusDataList = PostureStatusDataExtensions.getPostureStatusDataList(response);
    }
  }

  postureStatusDataList: PostureStatusData[];
}
