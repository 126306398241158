import { PostureUserData } from './posture-user-data';

export class PostureUserDataExtensions {
  static getPostureUserDataList(data) {
    const postureUserDataList = [];

    if (data) {
      data.forEach(item => {
        postureUserDataList.push(new PostureUserData(item));
      });
    }

    return postureUserDataList;
  }
}
