import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PermissionGuard } from '../../shared/guards/permission.guard';
import { Role } from '../../shared/models/enums';

import { CorporationDashboardComponent } from './corporation-dashboard/corporation-dashboard.component';

export const routing: ModuleWithProviders = RouterModule.forChild([
  {
    path: 'corporation-dashboard',
    component: CorporationDashboardComponent,
    canActivate: [PermissionGuard],
    data: { roles: [Role.SuperAdministrator, Role.CorporateAdministrator] }
  },
]);

