import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { routing } from './corporation.routing';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule, AlertModule } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { CorporationPageComponent } from './corporation-page/corporation-page.component';

import { CorporationService } from '../shared/services/corporation.service';
import { CorporationFilterPipe } from './corporation-filter.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    routing,
    DataTablesModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    NgSelectModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  declarations: [CorporationPageComponent, CorporationFilterPipe],
  providers: [CorporationService]
})
export class CorporationModule { }
