import { DatePipe } from '@angular/common';

export class DateHelper {
  static dateToString(date: Date) {
    return new DatePipe('en-IE').transform(date, 'dd/MM/yyyy');
  }

  static dateToISODateString(date: Date) {
    return new DatePipe('en-IE').transform(date, 'yyyy-MM-dd');
  }

  static getCurrentDate() {
    return new Date();
  }

  static getCurrentDateAsISODateString() {
    return this.dateToISODateString(new Date());
  }

  static getDateOneMonthAgo() {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  static getDateOneMonthAgoAsISODateString() {
    return this.dateToISODateString(this.getDateOneMonthAgo());
  }
}
