import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/throw';
import { Injector } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class BaseService {

  constructor(protected injector: Injector) { }

  protected handleError(error: any) {
    if (error.headers.get('www-authenticate').indexOf('expired')) {
      this.injector.get(AuthenticationService).logout();
    }

    const applicationError = error.headers.get('Application-Error');

    // either applicationError in header or model error in body
    if (applicationError) {
      return Observable.throw(applicationError);
    }

    let modelStateErrors = '';
    const serverError = error.json();

    if (!serverError.type) {
      for (const key in serverError) {
        if (serverError[key]) {
          modelStateErrors += serverError[key] + '\n';
        }
      }
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return Observable.throw(modelStateErrors || 'Server error');
  }

  protected getHttpHeaders() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const authService = this.injector.get(AuthenticationService);
    if (authService.isLogged()) {
      headers.append('Authorization', 'Bearer ' + authService.token.accessToken);
    }
    
    return headers;
  }
}
