import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { SharedModule } from '../shared/modules/shared.module';
import { routing } from './user.routing';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule, AlertModule } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { UserPageComponent } from './user-page/user-page.component';
import { UserResetPasswordPageComponent } from './user-resetpassword-page/user-resetpassword-page.component';

import { UserService } from '../shared/services/user.service';
import { UserFilterPipe } from './user-filter.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    SharedModule,
    routing,
    DataTablesModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    NgSelectModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  declarations: [UserPageComponent, UserResetPasswordPageComponent, UserFilterPipe],
  providers: [UserService]
})
export class UserModule { }
