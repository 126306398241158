import { UserExtensions } from './user-extensions';
import { User } from './user';

export class Users {
  constructor(response) {
    if (response) {
      this.users = UserExtensions.getUsers(response);
    }
  }

  users: User[];
}
