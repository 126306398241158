import { User } from './user';

export class UserExtensions {
  static getUsers(data) {
    const users = [];

    if (data) {
      data.forEach(item => {
        users.push(new User(item));
      });
    }

    return users;
  }
}
