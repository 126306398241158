import { Corporation } from './corporation';

export class CorporationExtensions {
  static getCorporations(data) {
    const corporations = [];

    if (data) {
      data.forEach(item => {
        corporations.push(new Corporation(item));
      });
    }

    return corporations;
  }
}
