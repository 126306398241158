import { CorporationActiveDevice } from './corporation-active-device';

export class CorporationActiveDeviceExtensions {
  static getCorporationActiveDevices(data) {
    const corporationActiveDevices = [];

    if (data) {
      data.forEach(item => {
        corporationActiveDevices.push(new CorporationActiveDevice(item));
      });
    }

    return corporationActiveDevices;
  }
}
