import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { Configuration } from '../models/configuration';
import { ApiTemplates } from '../utils/api-templates';

@Injectable()
export class ConfigurationService {
  private configData: Configuration;

  constructor(
    private http: HttpClient) { }

  loadConfigurationData(): Promise<Configuration> {
    return this.http.get(ApiTemplates.API_CONFIG.expand())
      .toPromise()
      .then((result: Configuration) => {
        this.configData = result;
        return this.configData;
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
  get config(): Configuration {
    return this.configData;
  }
}
