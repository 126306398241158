import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { routing } from './login.routing';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule, AlertModule } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { LoginPageComponent } from './login-page/login-page.component';

import { AuthenticationService } from '../shared/services/authentication.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    routing,
    DataTablesModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    NgSelectModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  declarations: [LoginPageComponent],
  providers: [AuthenticationService]
})
export class LoginModule { }
