import { CorporationExtensions } from './corporation-extensions';
import { Corporation } from './corporation';

export class Corporations {
  constructor(response) {
    if (response) {
      this.corporations = CorporationExtensions.getCorporations(response);
    }
  }

  corporations: Corporation[];
}
