import { Pipe, PipeTransform } from '@angular/core';
import { Corporation } from '../shared/models/corporation/corporation';

@Pipe({
  name: 'corporationFilter',
  pure: false
})
export class CorporationFilterPipe implements PipeTransform {
  transform(items: any[], filter: Corporation): any {
    if (!items || !filter) {
      return items;
    }
    
    return items.filter(item => item.code && filter.code && item.code.indexOf(filter.code) !== -1);
  }
}
