import { PostureData } from './posture-data';

export class PostureDataExtensions {
  static getPostureDataList(data) {
    const postureDataList = [];

    if (data) {
      data.forEach(item => {
        postureDataList.push(new PostureData(item));
      });
    }

    return postureDataList;
  }
}
