import { Pipe, PipeTransform } from '@angular/core';
import { EnumHelper } from '../utils/enum-helper';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, arg1: string): any {
    const keys = [];

    for (const enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        const enumValue = arg1 === 'readable' ? EnumHelper.toReadableString(value[enumMember]) : value[enumMember];
        keys.push({key: enumMember, value: enumValue});
      }
    }
    return keys;
  }
}
