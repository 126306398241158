import { CorporationActiveDeviceExtensions } from './corporation-active-device-extensions';
import { CorporationActiveDevice } from './corporation-active-device';

export class CorporationActiveDevices {
  constructor(response) {
    if (response) {
      this.corporationActiveDevices = CorporationActiveDeviceExtensions.getCorporationActiveDevices(response);
    }
  }

  corporationActiveDevices: CorporationActiveDevice[];
}
