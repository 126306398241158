import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Http } from '@angular/http';
import { ApiTemplates } from '../utils/api-templates';
import { Corporation } from '../models/corporation/corporation';
import { Corporations } from '../models/corporation/corporations';
import { CorporationActiveDevices } from '../models/corporation/corporation-active-devices';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CorporationService extends BaseService {
  constructor(private http: Http,
    injector: Injector) {
    super(injector);
  }

  getAll() {
    return this.http
      .get(
        ApiTemplates.CORPORATION_GETALL.expand(),
        { headers: this.getHttpHeaders() }
      )
      .map(res => new Corporations(res.json()))
      .catch(this.handleError);
  }

  getRealActiveDevices() {
    return this.http
      .get(
        ApiTemplates.CORPORATION_GETREALACTIVEDEVICES.expand(),
        { headers: this.getHttpHeaders() }
      )
      .map(res => new CorporationActiveDevices(res.json()))
      .catch(this.handleError)
  }

  create(corporation: Corporation) {
    return this.http
      .post(
        ApiTemplates.CORPORATION_CREATE.expand(),
        corporation, { headers: this.getHttpHeaders() }
      )
      .catch(this.handleError);
  }

  edit(corporation: Corporation) {
    return this.http
      .put(
        ApiTemplates.CORPORATION_EDIT.expand({ corporationId: corporation.id }),
        corporation, { headers: this.getHttpHeaders() }
      )
      .catch(this.handleError);
  }
}
