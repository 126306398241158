import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { Role } from '../shared/models/enums';

import { UserPageComponent } from './user-page/user-page.component';
import { UserResetPasswordPageComponent } from './user-resetpassword-page/user-resetpassword-page.component';

export const routing: ModuleWithProviders = RouterModule.forChild([
  {
    path: 'users',
    component: UserPageComponent,
    canActivate: [PermissionGuard],
    data: { roles: [Role.SuperAdministrator, Role.CorporateAdministrator, Role.User] }
  },
  {
    path: 'reset-password',
    component: UserResetPasswordPageComponent,
    canActivate: [PermissionGuard],
    data: { roles: [] }
  }
]);
