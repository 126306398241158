export enum Role {
  User = 1,
  SuperAdministrator = 2,
  CorporateAdministrator = 4
}

export enum JobType {
  Low = 0,
  Medium = 1,
  High = 2
}

export enum Gender {
  Nonspecified = 0,
  Male = 1,
  Female = 2
}

export enum Posture {
  Unknown = 0,
  Bad = 1,
  Good = 2
}

export enum AgeRange {
  To20 = 0,
  From20To24 = 1,
  From25To29 = 2,
  From30To34 = 3,
  From30To39 = 4,
  From40To44 = 5,
  From40To49 = 6,
  From50To54 = 7,
  From50To59 = 8,
  From60To64 = 9,
  From60To69 = 10,
  From70 = 11
}

export enum ExportDataOptions {
  Posture = 0,
  ActiveUsers = 1,
  StatusChanges = 2
}
