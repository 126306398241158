import { CorporationActiveDevice } from './corporation-active-device';

export class Corporation {
  constructor(item) {
    this.id = item.id;
    this.name = item.name;
    this.code = item.code;
    this.deviceCount = item.deviceCount;
    this.deviceCountDate = item.deviceCountDate;
    this.realActiveDevices = item.realActiveDevices;
    this.isActive = item.isActive;
  }

  id?: string;
  name: string;
  code: string;
  deviceCount: number;
  deviceCountDate: Date;
  realActiveDevices?: CorporationActiveDevice;
  isActive: boolean;
}
