import { PostureUserData } from './posture-user-data';
import { PostureUserDataExtensions } from './posture-user-data-extensions';

export class PostureUserDataList {
  constructor(response) {
    if (response) {
      this.postureUserDataList = PostureUserDataExtensions.getPostureUserDataList(response);
    }
  }

  postureUserDataList: PostureUserData[];
}
