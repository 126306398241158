import { Posture } from '../enums';

export class PostureStatusData {
  constructor(item) {
    this.Posture = item.posture;
    this.Hour = item.hour;
    this.Seconds = item.seconds;
  }

  Posture: Posture;
  Hour: number;
  Seconds: number;
}
