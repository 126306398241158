export class EnumHelper {
  static toReadableString(value: string) {
    value = value.replace(/([A-Z])/g, ' $1').trim();

    if (value.startsWith('To')) {
      value = value.replace('To', 'Under ');
    } else if (value.startsWith('From') && value.indexOf('To') > -1) {
      value = value.replace('From', '').replace(' To', '-');
    } else if (value.startsWith('From') && value.indexOf('To') === -1) {
      value = value.replace('From', '');
      value += '+';
    }

    return value;
  }
}
