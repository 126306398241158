export class PostureUserData {
  constructor(item) {
    this.Date = item.date;
    this.NumUsers = item.numUsers;
    this.DeviceCount = item.deviceCount;
  }

  Date: Date;
  NumUsers: number;
  DeviceCount: number;
}
