import * as template from 'url-template';

export class ApiTemplates {
  private static POSTUREFILTER = '{?corporationCode,dateMin,dateMax,heightMin,heightMax,weightMin,weightMax,gender,ageRange,jobType}';

  public static API_CONFIG = template.parse('/api/clientconfiguration');

  public static LOGIN = template.parse('/api/session{?username,password}');
  public static REFRESH_TOKEN = template.parse('/api/session/refresh');
  public static LOGINUSER_INFO = template.parse('/.auth/me');

  public static ADMINSTATS = template.parse('/api/adminstats');

  public static POSTUREDATA_GETPOSTUREBYDAY = template.parse('/api/posturedata/posturebyday' + ApiTemplates.POSTUREFILTER);
  public static POSTUREDATA_GETACTIVEUSERSBYDAY = template.parse('/api/posturedata/activeusersbyday' + ApiTemplates.POSTUREFILTER);
  public static POSTUREDATA_GETSTATUSCHANGESBYHOUR = template.parse('/api/posturedata/statuschangesbyhour' + ApiTemplates.POSTUREFILTER);

  public static CORPORATION_GETALL = template.parse('/api/corporation');
  public static CORPORATION_CREATE = template.parse('/api/corporation');
  public static CORPORATION_EDIT = template.parse('/api/corporation/{corporationId}');
  public static CORPORATION_GETREALACTIVEDEVICES = template.parse('/api/corporation/activedevices');

  public static USER_GET = template.parse('/api/user/byusername/{username}');
  public static USER_GETALL = template.parse('/api/user');
  public static USER_CREATE = template.parse('/api/user');
  public static USER_EDIT = template.parse('/api/user/{userId}');
  public static USER_RESETPASSWORD = template.parse('/api/user/{userId}/resetpassword');
}

