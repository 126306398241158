import { Component } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { User } from '../shared/models/user/user';
import { Role } from '../shared/models/enums';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;

  currentUser: User = this.authenticationService.loggedInUser;

  constructor(private authenticationService:AuthenticationService) { }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout() {
    this.authenticationService.logout();
  }

  // Role based menu 
  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.SuperAdministrator;
  }

  get isCorpAdmin() {
    return this.currentUser && this.currentUser.role === Role.CorporateAdministrator;
  }

  get isUser() {
    return this.currentUser && this.currentUser.role === Role.User;
  }
}
