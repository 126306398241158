import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/finally';

import { UserService } from '../../shared/services/user.service';
import { CorporationService } from '../../shared/services/corporation.service';
import { AuthenticationService } from '../../shared/services/authentication.service';

import { User } from '../../shared/models/user/user';
import { AgeRange, Gender, JobType, Role } from '../../shared/models/enums';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css']
})
export class UserPageComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective)
  dtUser: DataTableDirective;
  dtOptions: any = {};
  dtTrigger = new Subject();

  rolesList = Role;
  rolesKeys: any;

  modalRef: BsModalRef;
  modalOptions: ModalOptions = {
    backdrop: 'static',
    keyboard: false,
    class: 'modal-lg'
  }

  users: User[];
  model: User;
  editModel: User;

  corporationCodes: string[];
  ageRange = AgeRange;
  gender = Gender;
  jobType = JobType;

  isBusy = false;

  isShowWarningMessage = false;
  warningMessage: string;

  currentUser: User = this.authenticationService.currentUserValue;
  userIsCommonUser: boolean = this.currentUser.role === Role.User;
  userIsCorpAdmin: boolean = this.currentUser.role === Role.CorporateAdministrator;
  userFilterArgs;

  constructor(
    private modalService: BsModalService,
    private userService: UserService,
    private corporationService: CorporationService,
    private authenticationService: AuthenticationService
  ) {
    //this.rolesKeys = Object.keys(this.rolesList).filter((x) => Number.isNaN(parseInt(x)))
    this.rolesKeys = this.rolesList;

    if (this.currentUser && this.currentUser.role !== Role.SuperAdministrator) {
      delete this.rolesKeys[2];
      delete this.rolesKeys["SuperAdministrator"];
    }
  }


  ngOnInit() {
    this.initDataTable();
    this.getCorporationCodes();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  initProperties() {
    if (this.isShowWarningMessage) {
      this.isShowWarningMessage = false;
    }
  }

  getUsers() {
    this.userService.getAll().subscribe(result => {
        this.users = this.sortByOrder(result.users as User[]);
      },
      error => {
        console.log(error);
      }
    );
  }

  getCorporationCodes() {
    this.corporationService.getAll().subscribe(
      result => this.corporationCodes = result.corporations.map(u => u.code),
      error => console.log(error));
  }

  //#region DataTable
  initDataTable() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      aoColumndDefs: [{ bSortable: true, aTargets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }],
      aaSorting: []
    };

    if (this.currentUser && this.userIsCommonUser) {
      this.userFilterArgs = { id: this.currentUser.id };
    } else if (this.currentUser && this.userIsCorpAdmin) {
      this.userFilterArgs = { corporateCode: this.currentUser.corporateCode };
    }

    this.userService.getAll().subscribe(result => {
        this.users = this.sortByOrder(result.users as User[]);
        this.renderDataTable();
      },
      error => {
        console.log(error);
        this.users = [];
        this.renderDataTable();
      }
    );
  }

  renderDataTable(): void {
    this.dtTrigger.next();
  }

  //#endregion DataTable

  //#region Create User

  createUser(template: TemplateRef<any>) {
    this.model = {
      identifier: null,
      email: null,
      password: null,
      confirmPassword: null,
      oldPassword: null,
      firstName: null,
      lastName: null,
      corporateCode: null,
      role: null,
      gender: null,
      height: 170,
      weight: 60,
      ageRange: null,
      jobType: null
    };

    if (this.currentUser && this.userIsCorpAdmin) {
      this.model.corporateCode = this.currentUser.corporateCode;
    }
    this.initProperties();

    this.modalRef = this.modalService.show(template, this.modalOptions);
  }

  submitCreateUser() {
    this.isBusy = true;
    var model = this.model;
    this.userService.create(model)
      .finally(() => this.isBusy = false)
      .subscribe(result => {
        this.users.push(new User(result.json()));
        this.users = this.sortByOrder(this.users as User[]);
        this.modalRef.hide();
        },
        error => {
          console.log(error);
          this.warningMessage = error;
          this.isShowWarningMessage = true;
        }
      );
  }
  //#endregion Create User

  //#region Edit User
  editUser(template: TemplateRef<any>, user: User) {
    this.initProperties();
    this.editModel = Object.assign({}, user);

    console.log(this.editModel);

    this.modalRef = this.modalService.show(template, this.modalOptions);
  }

  submitEditUser() {
    this.isBusy = true;
    var editModel = this.editModel;
    this.userService.edit(editModel)
      .finally(() => this.isBusy = false)
      .subscribe(() => {
          this.users[this.users.findIndex(u => u.id === editModel.id)] = editModel;
          this.modalRef.hide();
        },
        error => {
          console.log(error);
          this.warningMessage = error;
          this.isShowWarningMessage = true;
        }
      );
  }
  //#endregion Edit User

  //#region Common functions
  private sortByOrder(list: any[]) {
    if (list) {
      return list.sort((a, b) => {
        const orderDiff = b.order - a.order;
        if (orderDiff) {
          return orderDiff;
        }
        return a.firstName.localeCompare(b.firstName); // Use a polyfill for IE support
      }).reverse();
    }

    return [];
  }

  trackByFn(index, item) {
    return index;
  }
}
