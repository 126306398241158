import { AdminStatsService } from '../shared/services/admin-stats.service';
import { AdminStats } from '../shared/models/dashboards/admin-stats';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  adminStats: AdminStats;

  constructor(private adminStatsService: AdminStatsService) {}

  ngOnInit() {
    this.adminStatsService.get()
      .subscribe(result => {
        this.adminStats = result;
      }, error => console.log(error));
  }
}
