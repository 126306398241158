import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionGuard } from './shared/guards/permission.guard';
import { Role } from './shared/models/enums';

import { HomeComponent } from './home/home.component';
import { CorporationPageComponent } from './corporation/corporation-page/corporation-page.component';
import { LoginPageComponent } from './login/login-page/login-page.component';
import { UserPageComponent } from './user/user-page/user-page.component';
import { CorporationDashboardComponent } from './dashboards/corporations/corporation-dashboard/corporation-dashboard.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [PermissionGuard],
    data: { roles: [Role.SuperAdministrator] }
  },
  {
    path: 'corporation-dashboard',
    component: CorporationDashboardComponent,
    canActivate: [PermissionGuard],
    data: { roles: [Role.SuperAdministrator, Role.CorporateAdministrator] }
  },
  {
    path: 'corporations',
    component: CorporationPageComponent,
    canActivate: [PermissionGuard],
    data: { roles: [Role.SuperAdministrator] }
  },
  {
    path: 'users',
    component: UserPageComponent,
    canActivate: [PermissionGuard],
    data: { roles: [Role.SuperAdministrator, Role.CorporateAdministrator, Role.User] }
  },
  {
    path: 'reset-password',
    component: HomeComponent,
    canActivate: [PermissionGuard],
    data: { roles: [] }
  },
  {
    path: 'login',
    component: LoginPageComponent
  },

  { path: '**', component: HomeComponent }

  //{ path: '', component: HomeComponent, pathMatch: 'full' },
  //{ path: '**', component: HomeComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
