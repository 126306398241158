import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Observable } from 'rxjs';
import 'rxjs/add/operator/finally';

import { CorporationService } from '../../shared/services/corporation.service';
import { AuthenticationService } from '../../shared/services/authentication.service';

import { Corporation } from '../../shared/models/corporation/corporation';
import { User } from '../../shared/models/user/user';
import { Role } from '../../shared/models/enums';
import { CorporationActiveDevice } from '../../shared/models/corporation/corporation-active-device';


@Component({
  selector: 'app-corporation-page',
  templateUrl: './corporation-page.component.html',
  styleUrls: ['./corporation-page.component.css']
})
export class CorporationPageComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective)
  dtCorporation: DataTableDirective;

  dtOptions: any = {};
  dtTrigger = new Subject();

  modalRef: BsModalRef;
  modalOptions: ModalOptions = {
    backdrop: 'static',
    keyboard: false,
    class: 'modal-lg'
  }

  corporations: Corporation[];
  model: Corporation;
  editModel: Corporation;

  isBusy = false;

  isShowWarningMessage = false;
  warningMessage: string;
  currentDeviceCount: number;
  editStartDate: string;
  editEndDate: string;

  currentUser: User = this.authenticationService.currentUserValue;
  userIsCorpAdmin: boolean = this.currentUser.role === Role.CorporateAdministrator;
  corpFilterArgs;
  codeActiveDevices: string;

  constructor(
    private modalService: BsModalService,
    private corporationService: CorporationService,
    private authenticationService: AuthenticationService
  ) { }


  ngOnInit() {
    this.initDataTable();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  initProperties() {
    if (this.isShowWarningMessage) {
      this.isShowWarningMessage = false;
    }
  }


  //#region DataTable
  initDataTable() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      aoColumndDefs: [{ bSortable: true, aTargets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }],
      aaSorting: []
    };

    if (this.currentUser && this.userIsCorpAdmin) {
      this.corpFilterArgs = { code: this.currentUser.corporateCode };
    }

    Observable.forkJoin(this.corporationService.getAll().toPromise(),
        this.corporationService.getRealActiveDevices().toPromise())
      .subscribe(results => {
          const [corpsResult, devicesResult] = results;

          corpsResult.corporations.forEach(
            c => c.realActiveDevices =
            devicesResult.corporationActiveDevices.find(x => x.corporateCode === c.code)
          );

          this.corporations = this.sortByOrder(corpsResult.corporations as Corporation[]);
          this.renderDataTable();
        },
        error => {
          console.log(error);
        }
    );
  }

  renderDataTable(): void {
    this.dtTrigger.next();
  }

  //#endregion DataTable

  //#region Create Corporation

  createCorporation(template: TemplateRef<any>) {
    this.model = {
      name: null,
      code: null,
      deviceCount: null,
      deviceCountDate: null,
      realActiveDevices: null,
      isActive: true
    };
    this.initProperties();

    this.modalRef = this.modalService.show(template, this.modalOptions);
  }

  submitCreateCorporation() {
    this.isBusy = true;
    
    if (this.model.deviceCount != null && this.model.deviceCountDate == null) {
      this.model.deviceCountDate = new Date();
    }

    this.corporationService.create(this.model)
      .finally(() => this.isBusy = false)
      .subscribe(() => {
        this.corporationService.getAll().subscribe(result => {
          this.corporations = this.sortByOrder(result.corporations as Corporation[]);
          this.modalRef.hide();
        },
          error => {
            console.log(error);
            this.corporations = [];
            this.renderDataTable();
            this.modalRef.hide();
          }
        );
      },
        error => {
          console.log(error);
          this.warningMessage = error;
          this.isShowWarningMessage = true;
        }
      );
  }
  //#endregion Create Corporation

  //#region Edit Corporation
  editCorporation(template: TemplateRef<any>, corporation: Corporation) {
    this.initProperties();
    this.editModel = Object.assign({}, corporation);
    this.currentDeviceCount = this.editModel.deviceCount;

    this.modalRef = this.modalService.show(template, this.modalOptions);
  }

  submitEditCorporation() {
    this.isBusy = true;

    if (this.currentDeviceCount === this.editModel.deviceCount) {
      this.editModel.deviceCountDate = null;
    }

    this.corporationService.edit(this.editModel)
      .finally(() => this.isBusy = false)
      .subscribe(() => {
          this.corporationService.getAll().subscribe(result => {
              this.corporations = this.sortByOrder(result.corporations as Corporation[]);
              this.modalRef.hide();
            },
            error => {
              console.log(error);
              this.corporations = [];
              this.renderDataTable();
              this.modalRef.hide();
            }
          );
        },
        error => {
          console.log(error);
          this.warningMessage = error;
          this.isShowWarningMessage = true;
        }
      );
  }
  //#endregion Edit Corporation

  //#region Common functions
  private sortByOrder(list: any[]) {
    if (list) {
      return list.sort((a, b) => {
        const orderDiff = b.order - a.order;
        if (orderDiff) {
          return orderDiff;
        }
        return a.name.localeCompare(b.name); // Use a polyfill for IE support
      }).reverse();
    }

    return [];
  }
}
