import { PostureDataExtensions } from './posture-data-extensions';
import { PostureData } from './posture-data';

export class PostureDataList {
  constructor(response) {
    if (response) {
      this.postureDataList = PostureDataExtensions.getPostureDataList(response);
    }
  }

  postureDataList: PostureData[];
}
