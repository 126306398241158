import { PostureStatusData } from './posture-status-data';

export class PostureStatusDataExtensions {
  static getPostureStatusDataList(data) {
    const postureStatusDataList = [];

    if (data) {
      data.forEach(item => {
        postureStatusDataList.push(new PostureStatusData(item));
      });
    }

    return postureStatusDataList;
  }
}
