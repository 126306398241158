import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  constructor(private authentication: AuthenticationService,
    private router: Router) { }

  username: string;
  password: string;
  error: boolean;
  isBusy: boolean;

  ngOnInit() {

  }

  ngOnDestroy(): void {

  }

  login() {
    this.error = false;
    this.isBusy = true;

    this.authentication.login(this.username, this.password)
      .then(() => this.router.navigate(["/"]))
      .catch(() => {
        this.error = true;
        this.isBusy = false;
      });
  }
}
